<template>
  <div>
    <div class="mt-4">
      <div class="d-flex justify-space-between align-center">
        <div class="font-weight-bold">เลือกชิ้นส่วนสะพาน</div>
        <div v-if="!isDialog" class="d-none d-sm-flex justify-end">
          <!-- <div style="font-size: 14px">ประวัติการแก้ไข</div>
          <div class="ml-2">
            <v-select outlined dense hide-details></v-select>
          </div> -->
          <v-btn
            v-if="bridgeOverview.permissions.can_edit"
            class="ml-auto elevation-0"
            @click="editBridgeComponentDialog = true"
          >
            <v-icon left> mdi-pencil-outline </v-icon>
            แก้ไข
          </v-btn>
          <v-btn
            v-if="bridgeOverview.permissions.can_add"
            class="ml-2 elevation-0 green white--text"
            @click="addBridgeComponentDialog = true"
          >
            <v-icon left> mdi-plus </v-icon>
            เพิ่มชิ้นส่วนสะพาน
          </v-btn>
        </div>
      </div>
      <v-row v-if="$vuetify.breakpoint.xs">
        <v-col sm="6">
          <v-select
            v-model="bridgeSelected"
            :items="bridgeList"
            item-text="name"
            item-value="partcode"
            outlined
            dense
            hide-details
            @change="changeBridge"
          ></v-select>
        </v-col>
        <v-col sm="6">
          <v-select
            v-model="bridgeViewSelectedForm"
            :items="bridgeView"
            item-text="name_th"
            item-value="name_eng"
            outlined
            dense
            hide-details
            @change="changeView"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-else class="mt-2" align="center">
        <v-col cols="auto">
          <v-btn-toggle v-model="bridgeSelected" mandatory color="primary">
            <v-btn
              v-for="bridge in bridgeList"
              :key="bridge.partcode"
              :value="bridge"
              @click="changeBridge(bridge.partcode)"
            >
              {{ bridge.name }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="auto">
          <v-btn-toggle
            v-model="bridgeViewSelectedForm"
            mandatory
            color="primary"
          >
            <v-btn
              v-for="view in bridgeView"
              :key="view.name_eng"
              :value="view.name_eng"
              @click="changeView(view.name_eng)"
            >
              {{ view.name_th }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </div>
    <v-card
      class="elevation-0 red-border mt-4"
      ref="cardbridgecomponent"
      :height="cardHeight"
      outlined
    >
      <BridgeOverview
        v-if="state == 'overview' && destroy == false"
        :bridgeType="bridgeTypeSelected"
        :bridgeView="bridgeViewSelected"
        :bridgePart="bridgePartSelected"
        :bridgeDirection="bridgeDirection"
        :bridgeSelected="bridgeSelected"
        :startLocation="startLocation"
        :endLocation="endLocation"
        :brFlag="brFlag"
        :imageDirection="$vuetify.breakpoint.smAndUp ? 'horizontal' : 'vertical'"
        :disableDirection="disableDirection"
        @clicked="changeToBridgeComponent"
      />
      <BridgeComponent
        v-if="state == 'component'"
        :bridgeView="bridgeViewSelected"
        :bridgeSegmentType="bridgeSegmentType"
        :bridgeDirection="bridgeDirection"
        :startLocation="startLocation"
        :endLocation="endLocation"
        :brFlag="brFlag"
        :bridgeComponentPartSelected="bridgeComponentPartSelected"
        :seq="bridgePartIndex"
        :bridgeSelected="bridgeSelected"
        @clicked="changeToOverview"
      />
    </v-card>

    <EditBridgeComponentDialog
      v-model="editBridgeComponentDialog"
      :bridge-selected="bridgeSelected"
      @update="updateBridgeComponent"
      @close="closeEditBridgeComponentDialog"
    />
    <AddBridgeComponentDialog
      v-model="addBridgeComponentDialog"
      :bridge-selected="bridgeSelected"
      :bridge-part="bridgeOverview.bridge_part"
      @update="updateBridgeComponent"
      @close="closeAddBridgeComponentDialog"
    />
  </div>
</template>

<script>
import EditBridgeComponentDialog from '@/components/dialog/EditBridgeComponentDialog.vue'
import AddBridgeComponentDialog from '@/components/dialog/AddBridgeComponentDialog.vue'
import BridgeOverview from '@/components/bridge/bridgeOverview.vue'
import BridgeComponent from '@/components/bridge/bridgeComponent.vue'
import utils from '@/assets/js/utils'
import { mapState } from 'vuex'
export default {
  components: {
    EditBridgeComponentDialog,
    AddBridgeComponentDialog,
    BridgeOverview,
    BridgeComponent
  },
  props: {
    isDialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    getKm: utils.getKm,

    bridgeInfo: {},
    showNBEs: false,
    isloading: false,
    isEditBridgeInfoDialogShow: false,

    state: 'init',
    brFlag: 1,
    bridgePartIndex: 1,
    bridgePartCount: 4,
    bridgeFootCount: 3,
    bridgeSlabLayer: 4,
    bridgeBsCount: 2,
    bridgeTypeSelected: 'BG',
    bridgeSegmentType: 'BG',
    bridgeViewSelected: 'topView',
    bridgeViewSelectedForm: 'topView',
    bridgeComponentPartSelected: 'A1',
    bridgeDirection: 'left',
    startLocation: 'กรุงเทพ',
    endLocation: 'ชลบุรี',
    imageDirection: 'vertical',
    disableDirection: false,
    bridgePart: [['A1', 'S1', 'S2', 'S3', 'S4', 'A2']],
    bridgeView: [
      { value: 'sideView', label: 'หน้าตัด' },
      { value: 'topView', label: 'บนสะพาน' },
      { value: 'bottomView', label: 'ใต้สะพาน' }
    ],
    bridgePartSelected: null,
    bridgePartList: [],
    bridgeSelected: null,
    bridgeList: [],
    destroy: false,

    editBridgeComponentDialog: false,
    addBridgeComponentDialog: false,
    cardHeight: 0
  }),
  mounted () {
    this.state = 'init'
    this.setBridgeOverview()
    this.setCardHeight()
    window.addEventListener('resize', this.setCardHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.setCardHeight)
  },
  computed: {
    ...mapState('bridgeDetail', ['bridgeOverview'])
  },
  methods: {
    async getBridgeGeneralDetail (partCode) {
      this.isBridgeGeneralLoading = true
      const payload = { bridgeId: this.$route.query.id, partCode }
      const bridgeInfo = await this.$store.dispatch(
        'getBridgeGeneralDetail',
        payload
      )
      // console.log(bridgeInfo)
      for (const key in bridgeInfo) {
        if (bridgeInfo[key] === null) {
          delete bridgeInfo[key]
        }
      }
      this.bridgeInfo = bridgeInfo
      this.setDetail()
      this.isBridgeGeneralLoading = false
    },
    updateInventoryDetailDialog (isShow) {
      this.isInventoryDetailDialogShow = isShow
    },
    setCardHeight () {
      const cardHeight =
        window.innerHeight -
        this.$refs.cardbridgecomponent.$el.getBoundingClientRect().y -
        50
      this.cardHeight = cardHeight > 350 ? cardHeight : 350
    },
    setBridgeOverview () {
      console.log('debug2')
      console.log(this.bridgeOverview)
      let bridgePart = []
      this.bridgePartSelected = []

      this.startLocation = this.bridgeOverview.startLocation
      this.endLocation = this.bridgeOverview.endLocation
      if (this.bridgeOverview.direction === 'ซ้ายทาง') {
        this.bridgeDirection = 'right'
      } else if (this.bridgeOverview.direction === 'ขวาทาง') {
        this.bridgeDirection = 'left'
      }

      const sideType = ['Box Culvert', 'Box Underpass']
      this.bridgeList = []

      for (let j = 0; j < this.bridgeOverview.bridge_part.length; j++) {
        this.bridgeList.push({
          partcode: this.bridgeOverview.bridge_part[j][0].partcode,
          type: this.bridgeOverview.bridge_part[j][0].bridge_strurture_name,
          name: `สะพาน ${this.bridgeOverview.bridge_part[j][0].partcode}`
        })
      }
      if (this.bridgeList.length > 0) {
        this.null_bridge = false
        if (!this.bridgeSelected) this.bridgeSelected = this.bridgeList[0]

        // this.bridgeSelected = { partcode: 'L1', type: 'Box Culvert' }

        this.bridgeTypeSelected = this.bridgeSelected.type

        let segmentHead = 'A'
        if (sideType.includes(this.bridgeSelected.type)) {
          segmentHead = 'WW'
        }
        if (this.bridgeOverview.has_a1) {
          bridgePart = [{ segment: segmentHead, seq: 1 }]
          // bridgePart = []
        } else {
          bridgePart = []
        }
        for (let i = 0; i < this.bridgeOverview.bridge_part.length; i++) {
          if (
            this.bridgeOverview.bridge_part[i][0].partcode ===
            this.bridgeSelected.partcode
          ) {
            for (
              let j = 1;
              j <= this.bridgeOverview.bridge_part[i].bridge_segment.length;
              j++
            ) {
              bridgePart.push({ segment: 'S', seq: j })
            }
          }
        }
        if (this.bridgeOverview.has_a2) {
          bridgePart.push({ segment: segmentHead, seq: 2 })
          // bridgePart = []
        }
        this.bridgePartSelected = bridgePart

        console.log('this.bridgePartSelected')
        console.log(this.bridgePartSelected)
        console.log(this.bridgeSelected)
        console.log(this.bridgeOverview)

        if (sideType.includes(this.bridgeSelected.type)) {
          this.imageDirection = 'horizontal'
          this.bridgeViewSelected = 'sideView'
          this.bridgeViewSelectedForm = 'sideView'
          this.bridgeView = [{ name_eng: 'sideView', name_th: 'หน้าตัด' }]
        } else {
          this.imageDirection = this.$vuetify.breakpoint.xs
            ? 'vertical'
            : 'horizontal'
          this.bridgeView = [
            { name_eng: 'topView', name_th: 'บนสะพาน' },
            { name_eng: 'bottomView', name_th: 'ใต้สะพาน' },
            { name_eng: 'sideView', name_th: 'หน้าตัด' }
          ]
          // if (this.bridgeViewSelected === 'sideView') {
          //   this.bridgeViewSelected = 'topView'
          // }
        }
      } else {
        this.null_bridge = true
        this.null_text = 'ไม่มีข้อมูลสะพาน'
      }
      this.state = 'overview'
    },

    setDetail () {
      this.bridgeInfo[11].value = this.getKm(this.bridgeInfo[11].value)
      // this.bridgeInfo.road_code.value = parseInt(this.detail.info.road_code)
    },
    changeToBridgeComponent (value) {
      if (value.back === true) {
        this.state = 'component'
        this.bridgeComponentPartSelected = value
        this.bridgePartIndex = parseInt(
          this.bridgeComponentPartSelected.componentNo
        )
        if (
          this.bridgeComponentPartSelected.componentID === 'P' ||
          (this.bridgeComponentPartSelected.componentID === 'A' &&
            this.bridgeViewSelectedForm === 'bottomView')
        ) {
          this.bridgeViewSelected = 'crossSectionView'
          for (let j = 0; j < this.bridgeOverview.bridge_part.length; j++) {
            if (
              this.bridgeOverview.bridge_part[j][0].partcode ===
              this.bridgePartSelected
            ) {
              this.bridgeSegmentType =
                this.bridgeOverview.bridge_part[j].bridge_segment[
                  this.bridgePartIndex - 1
                ].segment_type_id
            }
          }
        }
        if (
          this.bridgeComponentPartSelected.componentID === 'S' &&
          this.bridgeComponentPartSelected.componentNo === 1
        ) {
          this.brFlag = 1
        } else {
          this.brFlag = 0
        }
      }
    },
    changeToOverview (value) {
      if (value.back === true) {
        this.state = 'overview'
        if (this.bridgeViewSelected === 'crossSectionView') {
          this.bridgeViewSelected = 'bottomView'
          this.bridgeViewSelectedForm = 'bottomView'
        }
      }
    },
    changeView (view) {
      this.bridgeViewSelectedForm = view
      this.bridgeViewSelected = view
      this.setBridgeOverview()
    },
    changeBridge (value) {
      this.destroy = true

      let bridgeSelected = {}
      this.imageDirection = this.$vuetify.breakpoint.xs
        ? 'vertical'
        : 'horizontal'

      for (let j = 0; j < this.bridgeList.length; j++) {
        if (this.bridgeList[j].partcode === value) {
          bridgeSelected = this.bridgeList[j]
        }
      }

      this.bridgeSelected = bridgeSelected
      this.getBridgeGeneralDetail(this.bridgeSelected.partcode)

      let bridgePart = []
      this.bridgePartSelected = []
      const sideType = ['Box Culvert', 'Box Underpass']

      let segmentHead = 'A'
      if (sideType.includes(this.bridgeSelected.type)) {
        segmentHead = 'WW'
      }
      if (this.bridgeOverview.has_a1) {
        bridgePart = [{ segment: segmentHead, seq: 1 }]
        // bridgePart = []
      } else {
        bridgePart = []
      }
      for (let i = 0; i < this.bridgeOverview.bridge_part.length; i++) {
        if (
          this.bridgeOverview.bridge_part[i][0].partcode ===
          this.bridgeSelected.partcode
        ) {
          for (
            let j = 1;
            j <= this.bridgeOverview.bridge_part[i].bridge_segment.length;
            j++
          ) {
            bridgePart.push({ segment: 'S', seq: j })
          }
        }
      }
      if (this.bridgeOverview.has_a2) {
        bridgePart.push({ segment: segmentHead, seq: 2 })
        // bridgePart = []
      }
      this.bridgePartSelected = bridgePart

      setTimeout(() => {
        this.changeStateToOverview()
        this.changeToOverview({ back: true })
      }, 100)
    },
    changeStateToOverview () {
      this.destroy = false
    },
    closeAddBridgeComponentDialog () {
      // this.updateBridgeComponent()
      this.addBridgeComponentDialog = false
    },
    closeEditBridgeComponentDialog () {
      // this.updateBridgeComponent()
      this.editBridgeComponentDialog = false
    },
    updateBridgeComponent () {
      this.changeBridge(this.bridgeList[0].partcode)
    }
  }
}
</script>

<style lang="scss" scoped>
.red-border {
  border: 1px solid #c22d47;
}
.detail {
  font-family: 'Sarabun';
}
.btn-edit {
  margin-bottom: 90px;
}
.btn-add {
  margin-bottom: 160px;
}
</style>
