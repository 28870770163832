<template>
  <div>
    <TemplateDialog
      v-model="isOpenDialog"
      title="เพิ่มข้อมูลชิ้นส่วนสะพาน"
      :isUpdateLoading="isUpdateLoading"
      clearable
      @clear="clear()"
      @close="close()"
      @confirm="confirmDialog()"
    >
      <div v-if="isloaded">
        <v-form
          ref="form"
          lazy-validation
          class="pt-0"
          enctype="multipart/form-data"
        >
          <v-card class="primary-light" flat justify="center">
            <v-row class="pa-0 ma-0" justify="center">
              <v-col :cols="$vuetify.breakpoint.smAndUp ? 6 : 10">
                <v-row align="center">
                  <v-col cols="6">
                    <span class="pa-0 my__v-subheader">
                      จำนวนช่วงสะพาน (S)
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="amount_bridge"
                      class="center-input"
                      outlined
                      dense
                      type="number"
                      step="1"
                      min="0"
                      hide-details="auto"
                      placeholder="0"
                      background-color="white"
                      :disabled="isUpdateLoading"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <div class="pa-4">
            <div>
              <div>
                <div class="primary--text">
                  <h3>ค่าเริ่มต้นของช่วงสะพาน (S1)</h3>
                </div>
                <v-row class="mt-2" align="center">
                  <v-col
                    cols="12"
                    sm="6"
                    v-for="(item, index) in editSegmentField"
                    :key="index"
                  >
                    <v-row align="center">
                      <v-col cols="5">
                        <span class="pa-0 my__v-subheader">
                          {{ item.label }}
                        </span>
                        <span class="red--text"> *</span>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field
                          v-if="item.number"
                          v-model="editForm[item.model]"
                          class="right-input"
                          placeholder="0"
                          dense
                          outlined
                          type="number"
                          min="0"
                          pattern="\d*"
                          :rules="[rules.required]"
                          :disabled="isUpdateLoading"
                        ></v-text-field>
                        <v-select
                          v-else-if="item.select"
                          v-model="editForm[item.model]"
                          placeholder="เลือก"
                          dense
                          outlined
                          :items="selectionForm[item.select.list]"
                          item-text="name"
                          item-value="id"
                          :rules="[rules.required]"
                          :disabled="isUpdateLoading"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    editForm.segment_type_id !== 'BC' &&
                    editForm.segment_type_id !== 'BU'
                  "
                  align="center"
                >
                  <v-col cols="12" sm="6">
                    <v-row align="center">
                      <v-col cols="5">
                        <span class="pa-0 my__v-subheader">
                          จำนวนคานตามยาว (GB)
                        </span>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field
                          v-model="editForm.amount_beam_major"
                          class="right-input"
                          placeholder="0"
                          dense
                          outlined
                          type="number"
                          step="1"
                          min="0"
                          pattern="\d*"
                          :disabled="isUpdateLoading"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row align="center">
                      <v-col cols="5">
                        <span class="pa-0 my__v-subheader">
                          จำนวนค้ำยันคานตามยาว (DP)
                        </span>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field
                          v-model="editForm.amount_beam_minor"
                          class="right-input"
                          placeholder="0"
                          dense
                          outlined
                          type="number"
                          step="1"
                          min="0"
                          pattern="\d*"
                          :disabled="isUpdateLoading"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div
                v-if="
                  editForm.segment_type_id !== 'BC' &&
                  editForm.segment_type_id !== 'BU'
                "
                class="mt-4"
              >
                <div class="primary--text">
                  <h3>ค่าเริ่มต้นของตอม่อ (P1)</h3>
                </div>
                <v-row class="mt-2" align="center">
                  <v-col
                    cols="12"
                    sm="6"
                    v-for="(item, index) in editPierField"
                    :key="index"
                  >
                    <v-row align="center">
                      <v-col cols="5">
                        <span class="my__v-subheader">{{ item.label }}</span>
                        <span v-if="item.isrequired" class="red--text">
                          *
                        </span>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field
                          v-if="item.number"
                          v-model="editForm[item.model]"
                          class="right-input"
                          placeholder="0"
                          dense
                          outlined
                          type="number"
                          min="0"
                          pattern="\d*"
                          :rules="item.isrequired ? [rules.required] : []"
                          :disabled="isUpdateLoading"
                        ></v-text-field>
                        <v-select
                          v-else-if="item.select"
                          v-model="editForm[item.model]"
                          placeholder="เลือก"
                          dense
                          outlined
                          :items="selectionForm[item.select.list]"
                          item-text="name"
                          item-value="id"
                          :rules="item.isrequired ? [rules.required] : []"
                          :disabled="isUpdateLoading"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div
                v-if="
                  editForm.segment_type_id !== 'BC' &&
                  editForm.segment_type_id !== 'BU'
                "
                class="d-flex justify-center"
              >
                <v-checkbox
                  v-model="is_bridge_end"
                  :label="`สิ้นสุดสะพาน`"
                ></v-checkbox>
              </div>
            </div>
          </div>
        </v-form>
      </div>
      <div v-else class="fill-height">
        <v-row align="center" class="fill-height ma-0" justify="center">
          <v-progress-circular
            color="grey lighten-2"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </div>
    </TemplateDialog>
    <ConfirmDialog
      v-model="isConfirmDialog"
      :dialog-title="'ยืนยันการแก้ไขข้อมูล ?'"
      :isLoading="isUpdateLoading"
      @confirm="updateBridgeInfo()"
      @cancel="closeConfirm()"
    />
  </div>
</template>

<script>
import TemplateDialog from './TemplateDialog.vue'
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
export default {
  components: {
    TemplateDialog,
    ConfirmDialog
  },
  props: {
    value: Boolean,
    bridgeSelected: Object,
    bridgePart: Array
  },
  watch: {
    async value (newVal, oldVal) {
      this.isOpenDialog = newVal
      if (newVal) {
        if (this.$refs.form) this.$refs.form.reset()
        this.isloaded = false
        if (!this.selectionForm.segment_type) {
          await this.getRef('segment_type')
        }
        if (!this.selectionForm.segment_material) {
          await this.getRef('segment_material')
        }
        if (!this.selectionForm.pier_protection) {
          await this.getRef('pier_protection')
        }
        if (!this.selectionForm.pier_br) await this.getRef('pier_br')
        if (!this.selectionForm.pier_type) await this.getRef('pier_type')
        if (!this.selectionForm.pier_material) {
          await this.getRef('pier_material')
        }
        this.isloaded = true
      }
    }
  },
  computed: {
    selectionForm: {
      get () {
        return this.$store.state.ref
      },
      set (ref) {
        this.$store.commit('ref/SET_SELECTION', ref)
      }
    }
  },
  data: () => ({
    isOpenDialog: false,
    amount_bridge: 1,
    editSegmentField: [
      {
        model: 'segment_length',
        label: 'ความยาวช่วงสะพาน (ม.)',
        number: true
      },
      {
        model: 'segment_material_id',
        label: 'วัสดุโครงสร้างสะพาน',
        select: {
          list: 'segment_material'
        }
      },
      {
        model: 'segment_type_id',
        label: 'ประเภทโครงสร้างสะพาน',
        select: {
          list: 'segment_type'
        }
      }
    ],
    editPierField: [
      {
        model: 'pier_protection',
        label: 'การป้องกันตอม่อ',
        select: {
          list: 'pier_protection'
        },
        isrequired: false
      },
      {
        model: 'pier_br',
        label: 'ชนิดแผ่นรองรับคาน',
        select: {
          list: 'pier_br'
        },
        isrequired: true
      },
      {
        model: 'amount_cb',
        label: 'จำนวนคานรัดหัวเสา (CB)',
        number: true,
        isrequired: true
      },
      {
        model: 'amount_br',
        label: 'จำนวนแผ่นรองรับคาน',
        number: true,
        isrequired: true
      },
      {
        model: 'pier_type',
        label: 'ประเภทตอม่อ',
        select: {
          list: 'pier_type'
        },
        isrequired: true
      },
      {
        model: 'pier_material',
        label: 'วัสดุตอม่อ',
        select: {
          list: 'pier_material'
        },
        isrequired: true
      },
      {
        model: 'amount_pr',
        label: 'จำนวนตอม่อ (PR)',
        number: true,
        isrequired: true
      },
      {
        model: 'amount_bc',
        label: 'จำนวนค้ำยันตอม่อ (BC)',
        number: true,
        isrequired: true
      }
    ],
    isConfirmDialog: false,
    isloaded: false,
    isUpdateLoading: false,
    editForm: {},
    rules: {
      required: (value) => !!value || 'กรุณากรอกข้อมูล'
    },
    is_bridge_end: false
  }),
  methods: {
    close () {
      this.editForm = {}
      this.$emit('close', false)
    },

    clear () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    },

    async updateBridgeInfo () {
      this.isUpdateLoading = true
      const bridgePart = this.bridgePart.find(
        (bridge) => bridge[0].partcode === this.bridgeSelected.partcode
      )
      const bridgepartid = bridgePart[0].bridge_part_id
      const segmentForm = {
        amount_segment: this.amount_bridge,
        segment_length: this.editForm.segment_length,
        segment_type_id: this.editForm.segment_type_id,
        segment_material_id: this.editForm.segment_material_id,
        amount_beam_major: this.editForm.amount_beam_major,
        amount_beam_minor: this.editForm.amount_beam_minor
      }
      console.log('set bridge segment')
      console.log(bridgepartid, segmentForm)
      const isSegmentUpdate = await this.$store.dispatch('setBridgeSegment', {
        id: bridgepartid,
        seq: 0,
        payload: segmentForm
      })
      console.log(isSegmentUpdate)
      if (
        this.editForm.segment_type_id !== 'BC' &&
        this.editForm.segment_type_id !== 'BU' &&
        isSegmentUpdate
      ) {
        const pierForm = {
          amount_segment: this.amount_bridge, //
          is_bridge_end: this.is_bridge_end ? 1 : 0,
          pier_type_id: this.editForm.pier_type,
          pier_br_id: this.editForm.pier_br,
          amont_pr: this.editForm.amount_pr,
          amount_bc: this.editForm.amount_bc,
          amount_cb: this.editForm.amount_cb,
          amount_br: this.editForm.amount_br,
          pier_protection_id: this.editForm.pier_protection,
          pier_material_id: this.editForm.pier_material
        }
        console.log('set bridge pier')
        console.log(bridgepartid, pierForm)
        const isPierUpdate = await this.$store.dispatch('setBridgePier', {
          id: bridgepartid,
          seq: 0,
          payload: pierForm
        })
        console.log(isPierUpdate)
        if (isSegmentUpdate && isPierUpdate) {
          const payload = { bridgeCode: this.$route.query.id }
          await this.$store.dispatch('getBridgeOverview', payload)
          this.editForm = {}
          this.$emit('update')
          this.$emit('close')
        }
      } else {
        if (isSegmentUpdate) {
          const payload = { bridgeCode: this.$route.query.id }
          await this.$store.dispatch('getBridgeOverview', payload)
          this.editForm = {}
          this.$emit('update')
          this.$emit('close')
        }
      }

      this.isUpdateLoading = false
      this.isConfirmDialog = false
      console.log('finish')
    },
    closeConfirm () {
      this.isConfirmDialog = false
    },
    async getRef (ref) {
      const selection = await this.$store.dispatch('ref/getRef', {
        method: ref
      })
      this.selectionForm = { ref, selection: selection }
    },
    confirmDialog () {
      if (this.$refs.form.validate()) {
        this.isConfirmDialog = true
      } else {
        const invalidField = this.$refs.form.inputs.find((e) => !e.valid)
        if (invalidField) {
          invalidField.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
.center-input >>> input {
  text-align: center;
}
</style>
