<template>
  <div>
    <v-dialog
      v-model="isOpenDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
      :transition="
        $vuetify.breakpoint.xs
          ? 'dialog-bottom-transition'
          : 'dialog-transition'
      "
      max-width="760"
      persistent
    >
      <v-card>
        <div class="primary pb-2 white--text">
          <v-card-title>
            แก้ไขข้อมูล
            <v-btn icon class="ml-auto white--text" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="pb-0 primary">
            {{ bridgeName }}
          </v-card-subtitle>
        </div>
        <v-divider class="mb-2"></v-divider>

        <v-card-text v-if="!isLoading">
          <v-form v-model="isFormValid" ref="form">
            <v-row class="mt-4" v-if="editForm.nbesJson">
              <v-col
                cols="12"
                sm="6"
                class="my-0 py-0"
                v-for="(item, index) in infoBridge"
                :key="index"
              >
                <div class="form-text mb-1 black--text">
                  {{ item.name }}
                  <span v-if="item.isrequired" class="red--text"> * </span>
                </div>
                <v-autocomplete
                  v-if="item.model === 'amphoe_code'"
                  v-model="editForm[item.model]"
                  :items="amphoeByProvince"
                  outlined
                  dense
                  clearable
                  :item-text="item.autocomplete['item-text']"
                  :item-value="item.autocomplete['item-value']"
                  :no-data-text="item.autocomplete.nodata"
                  :disabled="isUpdateLoading"
                  :rules="item.isrequired ? [rules.required] : []"
                ></v-autocomplete>
                <v-autocomplete
                  v-else-if="item.autocomplete"
                  v-model="editForm[item.model]"
                  :items="itemLists[item.autocomplete.list]"
                  outlined
                  dense
                  clearable
                  :item-text="item.autocomplete['item-text']"
                  :item-value="item.autocomplete['item-value']"
                  :no-data-text="item.autocomplete.nodata"
                  :rules="item.isrequired ? [rules.required] : []"
                  :disabled="isUpdateLoading"
                ></v-autocomplete>
                <v-select
                  v-else-if="item.select"
                  v-model="editForm[item.model]"
                  :items="filterLoading ? itemLists[item.select.items] : []"
                  placeholder="เลือก"
                  outlined
                  dense
                  background-color="white"
                  :item-value="item.select['item-value']"
                  :item-text="item.select['item-text']"
                  :rules="item.isrequired ? [rules.required] : []"
                  :disabled="isUpdateLoading"
                ></v-select>
                <v-text-field
                  v-else-if="item.model === 'width'"
                  v-model="editForm[item.model]"
                  class="right-input"
                  type="number"
                  min="0"
                  outlined
                  dense
                  :rules="item.isrequired ? [rules.required] : []"
                  :disabled="isUpdateLoading"
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="editForm[item.model]"
                  outlined
                  dense
                  :rules="item.isrequired ? [rules.required] : []"
                  :disabled="isUpdateLoading"
                ></v-text-field>
              </v-col>
            </v-row>
            <div v-for="group in bridgeInfo" :key="group.id" class="mt-2">
              <div
                class="d-flex justify-center pa-2 primary white--text rounded"
              >
                <b>{{ group.name }}</b>
              </div>
              <v-row class="pa-0 my-2">
                <v-col
                  cols="12"
                  sm="6"
                  class="d-flex my-0 py-0"
                  v-for="(nbes, index) in group.items"
                  :key="index"
                >
                  <v-container class="px-0">
                    <div class="form-text mb-1 black--text">
                      {{ nbes.name }} ({{ nbes.code }})
                      <span class="red--text"> * </span>
                    </div>
                    <div>
                      <v-select
                        v-if="nbes.code === '21'"
                        v-model="editForm.nbesJson[nbes.code]"
                        :items="itemLists['v-mbmms-district']"
                        class="text-no-wrap"
                        item-text="name"
                        item-value="district_code"
                        outlined
                        dense
                        single-line
                        hide-details="auto"
                        placeholder="เลือก"
                        :rules="[rules.required]"
                        :disabled="isUpdateLoading || nbes.readonly"
                      >
                        <template v-slot:item="{ item }">
                          <span class="py-2" style="width: 300px"
                            >{{ item.name }} &nbsp;
                          </span>
                        </template>
                      </v-select>
                      <v-select
                        v-else-if="nbes.type.name === 'ref'"
                        v-model="editForm.nbesJson[nbes.code]"
                        :items="
                          nbes.type.refTable.substring(0, 4) === 'ref_'
                            ? itemLists[nbes.type.refTable.substring(4)]
                            : itemLists[nbes.type.refTable]
                        "
                        placeholder="เลือก"
                        class="text-no-wrap"
                        item-text="name"
                        :item-value="
                          nbes.code in nbesRef ? nbesRef[nbes.code].id : 'id'
                        "
                        outlined
                        dense
                        single-line
                        hide-details="auto"
                        :rules="[rules.required]"
                        :disabled="isUpdateLoading || nbes.readonly"
                      >
                        <template v-slot:item="{ item }">
                          <span class="py-2" style="width: 300px"
                            >{{ item.name }} &nbsp;
                          </span>
                        </template>
                      </v-select>
                      <v-text-field
                        v-else-if="nbes.code === '11'"
                        v-model="editForm.nbesJson[nbes.code]"
                        class="right-input"
                        placeholder="0+000"
                        :rules="kmRules"
                        outlined
                        dense
                        background-color="white"
                        hide-details="auto"
                        :disabled="isUpdateLoading || nbes.readonly"
                        @keypress="filterkm"
                      ></v-text-field>
                      <v-text-field
                        v-else-if="nbes.type.name === 'double'"
                        v-model="editForm.nbesJson[nbes.code]"
                        type="number"
                        placeholder="0"
                        step="0.0001"
                        class="right-input"
                        min="0"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[rules.required]"
                        :disabled="isUpdateLoading || nbes.readonly"
                      ></v-text-field>
                      <v-text-field
                        v-else-if="nbes.type.name === 'integer'"
                        v-model="editForm.nbesJson[nbes.code]"
                        type="number"
                        step="1"
                        class="right-input"
                        min="0"
                        placeholder="0"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[rules.required]"
                        :disabled="isUpdateLoading || nbes.readonly"
                      ></v-text-field>
                      <div v-else-if="nbes.type.name === 'date'">
                        <DatePicker
                          v-model="editForm.nbesJson[nbes.code]"
                          :rules="[rules.required]"
                          :disabled="isUpdateLoading || nbes.readonly"
                          class="mt-1"
                        />
                      </div>
                      <v-text-field
                        v-else
                        v-model="editForm.nbesJson[nbes.code]"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[rules.required]"
                        :disabled="isUpdateLoading || nbes.readonly"
                      ></v-text-field>
                    </div>
                  </v-container>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-card-text v-else>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-progress-circular
              color="grey lighten-2"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn elevation="0" style="width: 140px" @click="close">
            ยกเลิก
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="green white--text"
            elevation="0"
            style="width: 140px"
            @click="confirmUpdate"
            :disabled="isLoading || isUpdateLoading"
          >
            แก้ไข
            <v-progress-circular
              v-if="isUpdateLoading"
              color="white"
              :size="20"
              indeterminate
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-model="isConfirmDialog"
      :dialog-title="dialogTitle"
      :isLoading="isUpdateLoading"
      @confirm="updateBridgeInfo()"
      @cancel="closeConfirm()"
    />
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue'
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'

import utils from '@/assets/js/utils'

export default {
  components: {
    ConfirmDialog,
    DatePicker
  },
  props: {
    value: Boolean,
    bridgeDetail: Object,
    bridgeInfo: Object,
    groupCode: Number
  },
  data: () => ({
    getKm: utils.getKm,
    isOpenDialog: false,

    editForm: {
      nbesJson: {}
    },
    selectionList: {},
    isLoading: false,
    isUpdateLoading: false,
    dialogTitle: 'ยืนยันการแก้ไขข้อมูล ?',
    isConfirmDialog: false,
    nbesRef: {
      '5D': { method: 'road', id: 'road_id' }
    },
    filterLoading: false,
    amphoeByProvince: [],
    itemLists: {},
    rules: {
      required: (value) => !!value || 'กรุณากรอกข้อมูล'
    },
    isFormValid: false
  }),
  watch: {
    async value (newVal, oldVal) {
      this.isOpenDialog = newVal
      if (newVal) {
        this.isLoading = true
        this.editForm = {
          group_code: this.groupCode,
          nbesJson: {}
        }
        if (this.groupCode === 1) {
          await this.getRef('amphoe')
          await this.getRef('province')
          await this.getRef('joint_type')
          const amphoeCode = this.itemLists.amphoe.find(
            (x) => x.name_th === this.bridgeDetail.info.amphoe
          )
          const jointType = this.itemLists.joint_type.find(
            (joint) => joint.name === this.bridgeDetail.info.joint_type
          )
          this.editForm = {
            name: this.bridgeDetail.info.name,
            group_code: this.groupCode,
            road: this.bridgeDetail.info.road_id,
            section_part_id: this.bridgeDetail.info.section_part_id,
            the_geom: this.bridgeDetail.info.geom,
            width: this.bridgeDetail.info.width,
            province_code: amphoeCode.province_code,
            amphoe_code: amphoeCode.amphoe_code,
            ref_joint_type_id: jointType ? jointType.id : null,
            nbesJson: {}
          }
          await this.getRefRoad()
        }
        for (var group of Object.values(this.bridgeInfo)) {
          for (var nbes of Object.values(group.items)) {
            if (nbes.type.name === 'ref') {
              const ref =
                nbes.type.refTable.substring(0, 4) === 'ref_'
                  ? nbes.type.refTable.substring(4)
                  : nbes.type.refTable
              if (nbes.code === '21') {
                await this.getRef('v-mbmms-district')
              } else {
                await this.getRef(ref)
              }
            }
            if (nbes.code === '11' && nbes.valueEdit) {
              console.log(nbes.valueEdit)
              this.editForm.nbesJson[nbes.code] = this.getKm(nbes.valueEdit)
            } else this.editForm.nbesJson[nbes.code] = nbes.valueEdit
          }
        }

        // for (const nbes of Object.values(this.bridgeInfo)) {
        //   if (nbes.code in this.nbesRef) {
        //     if (!this.selectionForm[this.nbesRef[nbes.code].method]) {
        //       await this.getRef(this.nbesRef[nbes.code].method)
        //     }
        //   }
        this.isLoading = false
      } else {
        this.editForm = {
          group_code: this.groupCode,
          nbesJson: {}
        }
      }
    },
    'editForm.province_code' (newVal, oldVal) {
      if (newVal) {
        console.log(newVal)
        this.setAmphoeList()
        if (oldVal !== undefined) {
          this.editForm.amphoe_code = null
        }
      } else {
        this.amphoeByProvince = []
        this.editForm.amphoe_code = null
      }
    },
    'editForm.road': {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal) {
          this.editForm.section_part_id = null
          if (newVal === this.bridgeDetail.info.road_id) {
            this.editForm.section_part_id =
              this.bridgeDetail.info.section_part_id
          }
          this.getSectionPart(newVal)
        }
      }
    }
  },
  computed: {
    bridgeName () {
      return this.$store.state.appbarText.header
    },
    selectionForm: {
      get () {
        return this.$store.state.ref
      },
      set (ref) {
        this.$store.commit('ref/SET_SELECTION', ref)
      }
    },
    amphoeList: {
      get () {
        return this.$store.state.ref.amphoe
      },
      set (amphoe) {
        this.$store.commit('ref/SET_AMPHOE', amphoe)
      }
    },
    provinceList: {
      get () {
        return this.$store.state.ref.province
      },
      set (province) {
        this.$store.commit('ref/SET_PROVINCE', province)
      }
    },
    infoBridge () {
      return this.groupCode === 1
        ? [
          {
            name: 'ชื่อ',
            model: 'name',
            isrequired: true
          },
          {
            name: 'ความกว้างสะพาน (ม.)',
            model: 'width',
            isrequired: true
          },
          {
            name: 'หมายเลขทางหลวง',
            model: 'road',
            isrequired: true,
            select: {
              items: 'road',
              'item-value': 'road_id',
              'item-text': 'code'
            }
          },
          {
            name: 'ตอนควบคุม',
            model: 'section_part_id',
            isrequired: true,
            select: {
              items: 'control',
              'item-value': 'section_part_id',
              'item-text': 'label'
            }
          },
          {
            name: 'จังหวัด',
            model: 'province_code',
            isrequired: true,
            autocomplete: {
              list: 'province',
              'item-text': 'name_th',
              'item-value': 'province_code'
            }
          },
          {
            name: 'อำเภอ/เขต',
            model: 'amphoe_code',
            isrequired: true,
            autocomplete: {
              list: this.amphoeByProvince,
              'item-text': 'name_th',
              'item-value': 'amphoe_code',
              nodata: 'โปรดเลือกจังหวัด'
            }
          },
          {
            name: 'รูปแบบของรอยต่อ (Joint) สะพาน',
            model: 'ref_joint_type_id',
            isrequired: true,
            select: {
              items: 'joint_type',
              'item-value': 'id',
              'item-text': 'name'
            }
          }
        ]
        : []
    },
    kmRules () {
      const rules = []
      const rule = (v) =>
        (v && /^[0-9]+[+][0-9][0-9][0-9]$/.test(v)) ||
        /^[0-9]+[+][0-9][0-9][0-9]\.[0-9]+$/.test(v) ||
        'ผิดรูปแบบ'
      rules.push(rule)
      return rules
    }
  },
  methods: {
    close () {
      //   this.$refs.form.reset()
      this.$emit('updateBridgeInfoDialog', false)
    },

    confirmUpdate () {
      if (this.$refs.form.validate()) {
        this.isConfirmDialog = true
      } else {
        const invalidField = this.$refs.form.inputs.find((e) => !e.valid)
        if (invalidField) {
          invalidField.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }
    },

    async updateBridgeInfo () {
      const editForm = { ...this.editForm }
      if (editForm.nbesJson[11]) {
        editForm.nbesJson[11] = editForm.nbesJson[11].replace('+', '')
        editForm.km = editForm.nbesJson[11]
      }
      if (editForm.nbesJson[16] && editForm.nbesJson[17]) {
        editForm.the_geom = `POINT(${editForm.nbesJson[17]} ${editForm.nbesJson[16]})`
      }
      this.isUpdateLoading = true
      const isUpdate = await this.$store.dispatch('setBridgeInfo', {
        id: this.$route.query.id,
        payload: editForm
      })
      this.isConfirmDialog = false
      this.isUpdateLoading = false
      if (isUpdate) this.$emit('updateBridgeInfoDialog', true)
      else this.$emit('updateBridgeInfoDialog', false)
    },

    closeConfirm () {
      this.isConfirmDialog = false
    },

    setAmphoeList () {
      if (this.itemLists.amphoe) {
        this.amphoeByProvince = this.itemLists.amphoe.filter(
          (amphoe) => amphoe.province_code === this.editForm.province_code
        )
        console.log(this.amphoeByProvince)
      } else this.amphoeByProvince = []
    },
    async getRef (method) {
      this.itemLists[method] = await this.$store.dispatch('ref/getRef', {
        method
      })
    },
    async getRefRoad () {
      const roads = await this.$store.dispatch('getRefRoad', {})
      for (const road of roads) {
        road.code = parseInt(road.code)
      }
      this.itemLists.road = [...roads]
    },
    async getSectionPart (roadid) {
      this.filterLoading = false
      const controls = await this.$store.dispatch('getSectionPart', {
        roadid
      })
      for (const control of controls) {
        control.label = `${control.code} - ${control.name}`
      }
      this.itemLists.control = [...controls]
      this.filterLoading = true
    },
    filterkm (event) {
      event = event || window.event
      const expect = event.target.value.toString() + event.key.toString()
      if (
        !/^[0-9]*$/.test(expect) &&
        !/^[0-9]+[+]$/.test(expect) &&
        !/^[0-9]+[+][0-9]+\.?[0-9]*$/.test(expect)
      ) {
        event.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
