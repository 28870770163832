<template>
  <div>
    <div v-if="!isDialog" class="d-none d-sm-flex mt-2 align-center">
      <v-row align="center">
        <v-col cols="auto" style="font-size: 14px">ประวัติการแก้ไข</v-col>
        <v-col cols="4">
          <v-select
            v-model="revisionId"
            outlined
            dense
            hide-details
            :items="revisionList"
            item-text="created_date"
            item-value="id"
            full-width
            @change="getBridgeRevisionInfo()"
          >
            <template v-slot:item="{ item }">
              <div>{{ item.created_date }}</div>
            </template>
          </v-select>
        </v-col>
        <v-col>
          <v-btn
            v-if="bridgeOverview.permissions.can_edit"
            class="elevation-0"
            @click="openEditdialog"
            :disabled="isloading"
          >
            <v-icon left> mdi-pencil-outline </v-icon>
            แก้ไข
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-btn
      v-if="!isDialog && bridgeOverview.permissions.can_edit"
      fab
      fixed
      right
      bottom
      class="hidden-sm-and-up elevation-0 btn-edit green"
      @click="openEditdialog"
    >
      <v-icon color="white">mdi-pencil-outline</v-icon>
    </v-btn>
    <v-card class="elevation-0 red-border mt-4 pa-2" outlined>
      <div
        class="d-flex"
        :class="
          $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-space-between'
        "
      >
        <div class="hidden-sm-and-up primary--text font-weight-bold">
          โครงสร้างสะพาน
        </div>
        <v-checkbox
          v-model="showNBEs"
          class="mt-0 pt-0"
          :label="'แสดงรหัส NBI'"
        ></v-checkbox>
      </div>
      <div v-if="isloading" align="center" class="ma-0" justify="center">
        <v-progress-circular
          color="grey lighten-2"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-for="group in bridgeInfo" :key="group.id">
        <div class="d-flex justify-center pa-2 primary white--text rounded">
          <b>{{ group.name }}</b>
        </div>
        <v-row class="my-2">
          <v-col
            v-for="(data, index) in group.items"
            :key="index"
            cols="12"
            md="3"
            sm="6"
          >
            <div class="header-section">
              {{ data.name }} <span v-if="showNBEs">({{ data.code }})</span>
            </div>
            <div class="detail">{{ data.value || '-' }}</div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <EditBridgeInfoDialog
      v-model="isEditBridgeInfoDialogShow"
      :group-code="3"
      :bridge-info="bridgeInfo"
      @updateBridgeInfoDialog="updateBridgeInfoDialog"
    />
  </div>
</template>

<script>
import EditBridgeInfoDialog from '../../dialog/editBridgeInfoDialog.vue'
import utils from '@/assets/js/utils'
import { mapState } from 'vuex'

export default {
  components: {
    EditBridgeInfoDialog
  },
  props: {
    isDialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    bridgeInfo: {},
    showNBEs: false,
    isloading: true,

    isEditBridgeInfoDialogShow: false,

    // revision
    revisionList: [],
    revisionId: null
  }),
  computed: {
    ...mapState('bridgeDetail', ['bridgeOverview'])
  },
  mounted () {
    this.getBridgeRevisionList()
  },
  methods: {
    async getBridgeAttribute () {
      this.isloading = true
      this.bridgeInfo = await this.$store.dispatch('getBridgeAttribute', {
        id: this.$route.query.id,
        group: 3
      })
      this.isloading = false
    },
    getBridgeValue (info) {
      return this.bridgeInfo[info] ? this.bridgeInfo[info].value : ''
    },

    openEditdialog () {
      this.isEditBridgeInfoDialogShow = true
    },

    async updateBridgeInfoDialog (isUpdate) {
      this.isEditBridgeInfoDialogShow = false
      if (isUpdate) {
        this.bridgeInfo = {}
        await this.getBridgeRevisionList()
      }
    },
    async getBridgeRevisionList () {
      const revisionList = await this.$store.dispatch('getBridgeRevisionList', {
        bridgeId: this.$route.query.id,
        groupCode: 3
      })
      this.revisionList = this.setRevisionList(revisionList)
      this.revisionId = this.revisionList[0].id
      await this.getBridgeRevisionInfo()
    },
    setRevisionList (revisionList) {
      return revisionList.map((revision) => {
        return {
          id: revision.id,
          created_date: `${
            revision.revision === '0'
              ? 'ข้อมูลเริ่มต้น'
              : revision.is_latest
              ? 'ครั้งล่าสุด'
              : `ครั้งที่ ${revision.revision}`
          } (${this.stringToDateList(revision.created_date)} น.)`
        }
      })
    },
    async getBridgeRevisionInfo () {
      if (this.revisionId) {
        this.isloading = true
        this.bridgeInfo = null
        const revisionInfo = await this.$store.dispatch(
          'getBridgeRevisionInfo',
          {
            bridgeId: this.$route.query.id,
            groupCode: 3,
            revisionId: this.revisionId
          }
        )
        this.bridgeInfo = revisionInfo.nbes
        this.isloading = false
      } else {
        this.getBridgeAttribute()
      }
    },
    stringToDateList (str) {
      const dateObj = utils.stringToDate(str)
      if (isNaN(dateObj.getTime())) return str
      else {
        return dateObj.toLocaleString('th-TH', {
          dateStyle: 'medium',
          timeStyle: 'short'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.red-border {
  border: 1px solid #c22d47;
}

// .header-section {
//   font-weight: bold;
//   color: #c22d47;
// }

.detail {
  font-family: 'Sarabun';
  font-weight: bold;
  color: #c22d47;
}

.btn-edit {
  margin-bottom: 90px;
}
</style>
