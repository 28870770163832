<template>
  <div>
    <v-dialog v-model="isOpenDialog" scrollable max-width="400" persistent>
      <v-card>
        <div class="d-flex align-center pa-4 primary--text">
          <div class="">เลือกได้ครั้งละ 10 รายการ</div>
          <v-btn icon class="ml-auto primary--text" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-divider></v-divider>

        <v-card-text class="pa-4 pb-0">
          <div v-if="bridgeSegment.length > 0">
            <v-list v-if="bridgeSegment[0].seq !== null" flat>
              <v-list-item-group v-model="bridgeSelectedModel" multiple>
                <template v-for="(item, i) in bridgeSegment">
                  <v-list-item
                    v-if="item.seq !== null"
                    :key="`item-${i}`"
                    :value="item"
                    active-class="select-background"
                    :disabled="
                      bridgeSelectedModel.length === 10 &&
                      !bridgeSelectedModel.includes(item)
                    "
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          :disabled="
                            bridgeSelectedModel.length === 10 &&
                            !bridgeSelectedModel.includes(item)
                          "
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="`S${item.seq}`"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
            <div v-else class="pb-4">ไม่มีชิ้นส่วนสะพานให้เลือก</div>
          </div>
          <div v-else class="pb-4">ไม่มีชิ้นส่วนสะพานให้เลือก</div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn elevation="0" style="width: 140px" @click="clear">
            <v-icon left>mdi-close</v-icon>
            ล้างข้อมูล
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="blue white--text"
            elevation="0"
            style="width: 140px"
            :disabled="bridgeSelectedModel.length === 0"
            @click="confirm()"
          >
            เลือก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: String,
    bridgeSelected: {
      type: Object,
      default: () => {
        return { partcode: '' }
      }
    },
    bridgeSegmentSelected: Array
  },
  data: () => ({
    isOpenDialog: false,
    bridgeList: ['s1', 's2', 's3', 's4', 's5', 's6', 's7'],
    bridgeSelectedModel: []
  }),
  watch: {
    async value (newVal, oldVal) {
      this.isOpenDialog = newVal
      if (newVal) {
        const bridgeSegmentSelected = this.bridgeSegmentSelected.map((bridge) =>
          parseInt(bridge.seq)
        )
        this.bridgeSelectedModel = this.bridgeSegment.filter((bridge) =>
          bridgeSegmentSelected.includes(parseInt(bridge.seq))
        )
      }
    }
  },
  computed: {
    bridgeSegment () {
      if (this.bridgeSelected) {
        console.log(
          this.$store.state.bridgeDetail.bridgeOverview.bridge_part.find(
            (bridgepart) => {
              return bridgepart[0].partcode === this.bridgeSelected.partcode
            }
          ).bridge_segment
        )
        return this.$store.state.bridgeDetail.bridgeOverview.bridge_part.find(
          (bridgepart) => {
            return bridgepart[0].partcode === this.bridgeSelected.partcode
          }
        ).bridge_segment
      } else return []
    }
  },
  methods: {
    clear () {
      this.bridgeSelectedModel = []
    },

    confirm () {
      this.$emit('confirm', this.bridgeSelectedModel)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.select-background {
  background: #f9f9f9;
}
</style>
