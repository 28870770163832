<template>
  <div>
    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab v-for="(item, indextab) in historyTab" :key="indextab">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <InventoryMaintenanceContractTable class="mt-4 pa-1" />
      </v-tab-item>
      <v-tab-item>
        <InventoryMaintenanceContractPriceTable class="mt-4 pa-1" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  components: {
    InventoryMaintenanceContractTable: () =>
      import(
        '@/components/Inventory/Detail/InventoryMaintenanceContractTable.vue'
      ),
    InventoryMaintenanceContractPriceTable: () =>
      import(
        '@/components/Inventory/Detail/InventoryMaintenanceContractPriceTable.vue'
      )
  },
  data: () => ({
    tab: 0,
    historyTab: [
      {
        name: 'จ้างเหมาซ่อมสะพาน'
      },
      {
        name: 'จ้างเหมาวิธีตกลงราคา'
      }
    ]
  })
}
</script>

<style>
</style>
