<template>
  <div>
    <div v-if="!isDialog" class="d-none d-sm-flex mt-2 align-center">
      <v-row align="center">
        <v-col cols="auto" style="font-size: 14px">ประวัติการแก้ไข</v-col>
        <v-col cols="4">
          <v-select
            v-model="revisionId"
            outlined
            dense
            hide-details
            :items="revisionList"
            item-text="created_date"
            item-value="id"
            full-width
            @change="getBridgeRevisionInfo()"
          >
            <template v-slot:item="{ item }">
              <div>{{ item.created_date }}</div>
            </template>
          </v-select>
        </v-col>
        <v-col class="pa-0">
          <v-btn
            v-if="bridgeOverview.permissions.can_edit"
            class="elevation-0"
            elevation="0"
            @click="openEditdialog"
            :disabled="isloading"
          >
            <v-icon left> mdi-pencil-outline </v-icon>
            แก้ไข
          </v-btn>
          <v-btn
            v-if="bridgeOverview.permissions.can_delete"
            class="elevation-0 ml-2 primary"
            :disabled="isloading"
            @click="isConfirmDeleteDialog = true"
          >
            <v-icon left>mdi-trash-can-outline</v-icon>
            ลบสะพาน
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-btn
      v-if="!isDialog && bridgeOverview.permissions.can_delete"
      fab
      fixed
      right
      bottom
      :disabled="isloading"
      class="hidden-sm-and-up elevation-0 primary"
      :class="bridgeOverview.permissions.can_edit ? 'btn-third' : 'btn-second'"
      @click="isConfirmDeleteDialog = true"
    >
      <v-icon color="white">mdi-trash-can-outline</v-icon>
    </v-btn>
    <v-btn
      v-if="!isDialog && bridgeOverview.permissions.can_edit"
      fab
      fixed
      right
      bottom
      :disabled="isloading"
      class="hidden-sm-and-up elevation-0 green btn-second"
      @click="openEditdialog"
    >
      <v-icon color="white">mdi-pencil-outline</v-icon>
    </v-btn>
    <!-- <v-speed-dial
      v-model="fab"
      class="mb-6"
      color="grey lighten-2"
      bottom
      right
      fixed
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-dots-vertical </v-icon>
        </v-btn>
      </template>
      <v-btn fab dark color="blue" @click="showMap = true">
        <v-icon>mdi-map-outline</v-icon>
      </v-btn>
      <v-btn fab dark color="green" @click="openEditdialog">
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
      <v-btn fab dark color="primary" @click="isConfirmDeleteDialog = true">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-speed-dial> -->

    <v-card class="elevation-0 primary-border mt-4 pa-2" outlined>
      <div
        class="d-flex"
        :class="
          $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-space-between'
        "
      >
        <div class="hidden-sm-and-up primary--text font-weight-bold">
          ข้อมูลทั่วไป
        </div>
        <v-checkbox
          v-model="showNBEs"
          class="mt-0 pt-0"
          :label="'แสดงรหัส NBI'"
        ></v-checkbox>
      </div>
      <v-row class="pa-0 my-2">
        <v-col
          v-for="(data, index) in bridgeData"
          :key="index"
          cols="12"
          md="3"
          sm="6"
        >
          <div class="header-section">
            {{ data.title }}
            <span v-if="showNBEs && data.code">({{ data.code }})</span>
          </div>
          <div v-if="index === 'name'" class="detail">
            {{
              `${data.value} ${
                bridgeDetail.info.direction
                  ? ` (${bridgeDetail.info.direction})`
                  : ''
              }`
            }}
          </div>
          <div v-else class="detail">{{ data.value || '-' }}</div>
        </v-col>
        <v-col
          cols="12"
          v-if="isloading"
          align="center"
          class="ma-0"
          justify="center"
        >
          <v-progress-circular
            color="grey lighten-2"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
      <div v-for="group in bridgeInfo" :key="group.id" class="mt-2">
        <div class="d-flex justify-center pa-2 primary white--text rounded">
          <b>{{ group.name }}</b>
        </div>
        <v-row class="my-2">
          <v-col
            cols="12"
            v-if="isloading"
            align="center"
            class="ma-0"
            justify="center"
          >
            <v-progress-circular
              color="grey lighten-2"
              indeterminate
            ></v-progress-circular>
          </v-col>
          <v-col
            v-for="(data, index) in group.items"
            :key="index"
            cols="12"
            md="3"
            sm="6"
            v-show="data.code !== '11' && data.code !== '5D'"
          >
            <div class="header-section">
              {{ data.name }} <span v-if="showNBEs">({{ data.code }})</span>
            </div>
            <div class="detail">{{ data.value || '-' }}</div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <div class="mt-4 mb-16">
      <div
        class="font-weight-bold"
        :class="$vuetify.breakpoint.smAndUp ? '' : 'primary--text'"
      >
        ประวัติการซ่อมบำรุง
      </div>
      <InventoryDetailHistoryTab />
    </div>
    <EditBridgeInfoDialog
      v-model="isEditBridgeInfoDialogShow"
      :group-code="1"
      :bridge-detail="bridgeDetail"
      :bridge-info="bridgeInfo"
      @updateBridgeInfoDialog="updateBridgeInfoDialog"
    />
    <ConfirmDialog
      v-model="isConfirmDeleteDialog"
      dialog-title="ยืนยันการลบสะพาน"
      :dialog-icon="'mdi-close-circle-outline'"
      :dialog-icon-color="'primary'"
      @confirm="delBridge()"
      @cancel="closeConfirm()"
    />
  </div>
</template>

<script>
import EditBridgeInfoDialog from '../../dialog/editBridgeInfoDialog.vue'
import InventoryDetailHistoryTab from './InventoryDetailHistoryTab.vue'
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
import utils from '@/assets/js/utils'
import { mapState } from 'vuex'

export default {
  components: {
    EditBridgeInfoDialog,
    InventoryDetailHistoryTab,
    ConfirmDialog
  },
  props: {
    isDialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    historyList: [],
    bridgeInfo: {},
    showNBEs: false,
    isloading: true,

    isEditBridgeInfoDialogShow: false,
    bridgeDetail: null,
    getKm: utils.getKm,
    revisionList: [],
    revisionId: null,

    isConfirmDeleteDialog: false,
    fab: false
  }),
  computed: {
    ...mapState('bridgeDetail', ['bridgeOverview']),
    bridgeData () {
      if (this.bridgeDetail) {
        return {
          code: { title: 'รหัสสะพาน', value: this.bridgeDetail.info.code },
          name: {
            title: 'ชื่อสะพาน',
            value: this.bridgeDetail.info.name
          },
          joint_type: {
            title: 'รูปแบบของรอยต่อ (Joint) สะพาน',
            value: this.bridgeDetail.info.joint_type
          },
          depot: {
            title: 'สำนัก',
            value: this.bridgeDetail.info.division
          },
          restorationCenter: {
            title: 'ศูนย์สร้างและบูรณะสะพานที่',
            value: this.bridgeDetail.info.center_name // bridge_center
          },
          highwayNumber: {
            title: 'หมายเลขทางหลวง',
            value: '0',
            code: '5D'
          },
          highway: {
            title: 'แขวงทางหลวง',
            value: this.bridgeDetail.info.district
          },
          control: {
            title: 'ตอนควบคุม',
            value: this.bridgeDetail.info.section_part_code
          },
          km: {
            title: 'กม. ที่',
            value: this.getKm(0),
            code: '11'
          },
          amphoe: { title: 'อำเภอ/เขต', value: this.bridgeDetail.info.amphoe },
          province: { title: 'จังหวัด', value: this.bridgeDetail.info.province }
        }
      } else return {}
    }
  },

  mounted () {
    this.getBridgeRevisionList()
  },
  methods: {
    async getBridgeAttribute () {
      this.isloading = true
      this.bridgeInfo = await this.$store.dispatch('getBridgeAttribute', {
        id: this.$route.query.id,
        group: 1
      })
      this.bridgeData.km.value = this.bridgeInfo.kilometer_point
        ? this.getKm(this.bridgeInfo.kilometer_point.value)
        : this.getKm(0)
      this.bridgeData.highwayNumber.value = this.bridgeInfo.route_no
        ? parseInt(this.bridgeInfo.route_no.value)
        : '0'
      this.isloading = false
    },

    openEditdialog () {
      this.isEditBridgeInfoDialogShow = true
    },
    async updateBridgeInfoDialog (isUpdate) {
      this.isEditBridgeInfoDialogShow = false
      if (isUpdate) {
        this.bridgeInfo = {}
        await this.getBridgeDetail()
        await this.getBridgeRevisionList()
      }
    },
    async delBridge () {
      this.isConfirmDeleteDialog = false
      const result = await this.$store.dispatch('delBridge', {
        bridgeId: this.$route.query.id
      })
      if (result) {
        this.$router.push({
          path: '/bridge'
        })
      }
    },

    async getBridgeDetail () {
      this.isloading = true
      // this.$store.dispatch('bridgeDetail/resetState')
      const bridgeDetail = await this.$store.dispatch(
        'bridgeDetail/getBridgeDetail',
        {
          id: this.$route.query.id
        }
      )
      console.log(bridgeDetail)
      this.setAppbar(bridgeDetail)
      this.isloading = false
    },
    setAppbar (bridgeDetail) {
      const header = this.getHeaderName(bridgeDetail.info)
      this.$store.commit('appbarText/SET_HEADER', header)
      const subHeader = `ทล. ${parseInt(
        bridgeDetail.info.road_code
      )} กม. ${this.getKm(bridgeDetail.info.km || 0)}`
      this.$store.commit('appbarText/SET_SUB_HEADER', subHeader)
    },
    getHeaderName (info) {
      return `${info.name} ${info.direction ? `(${info.direction})` : ''}`
    },
    async getBridgeRevisionList () {
      const revisionList = await this.$store.dispatch('getBridgeRevisionList', {
        bridgeId: this.$route.query.id,
        groupCode: 1
      })
      this.revisionList = this.setRevisionList(revisionList)
      this.revisionId = this.revisionList[0].id
      await this.getBridgeRevisionInfo()
    },
    setRevisionList (revisionList) {
      return revisionList.map((revision) => {
        return {
          id: revision.id,
          created_date: `${
            revision.revision === '0'
              ? 'ข้อมูลเริ่มต้น'
              : revision.is_latest
              ? 'ครั้งล่าสุด'
              : `ครั้งที่ ${revision.revision}`
          } (${this.stringToDateList(revision.created_date)} น.)`
        }
      })
    },
    async getBridgeRevisionInfo () {
      if (this.revisionId) {
        this.isloading = true
        this.bridgeInfo = null
        const revisionInfo = await this.$store.dispatch(
          'getBridgeRevisionInfo',
          {
            bridgeId: this.$route.query.id,
            groupCode: 1,
            revisionId: this.revisionId
          }
        )
        this.bridgeDetail = revisionInfo.bridge_info
        this.bridgeInfo = revisionInfo.nbes
        this.bridgeData.km.value = this.bridgeDetail.info.km
          ? this.getKm(this.bridgeDetail.info.km)
          : this.getKm(0)
        this.bridgeData.highwayNumber.value = this.bridgeDetail.info.road_code
          ? parseInt(this.bridgeDetail.info.road_code)
          : '0'
        this.isloading = false
      } else {
        this.getBridgeAttribute()
      }
    },
    stringToDateList (str) {
      const dateObj = utils.stringToDate(str)
      if (isNaN(dateObj.getTime())) return str
      else {
        return dateObj.toLocaleString('th-TH', {
          dateStyle: 'medium',
          timeStyle: 'short'
        })
      }
    },

    closeConfirm () {
      this.isConfirmDeleteDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.background-table {
  background-color: #c22d47;
}
.primary-border {
  border: 1px solid #c22d47;
}

// .header-section {
// }

.detail {
  font-family: 'Sarabun';
  font-weight: bold;
  color: #c22d47;
}

.btn-second {
  margin-bottom: 90px;
  font-weight: bold;
}

.btn-third {
  margin-bottom: 156px;
  font-weight: bold;
}
</style>
