<template>
  <div>
    <TemplateDialog
      v-model="isOpenDialog"
      title="แก้ไขข้อมูล-ชิ้นส่วนสะพาน"
      :isDisabledSubmit="
        isloading ||
        bridgeSegmentSelected.length === 0 ||
        !bridgeOverview.permissions.can_edit
      "
      :isUpdateLoading="isUpdateLoading"
      @close="close()"
      @confirm="confirmDialog()"
    >
      <div class="pa-4">
        <div class="d-sm-flex">
          <v-btn
            class="aqua white--text mr-4"
            @click="selectBridgeComponentDialog = true"
          >
            <v-icon left>mdi-plus</v-icon>
            เลือกชิ้นส่วนสะพาน
          </v-btn>
          <div v-if="!isloading" :class="$vuetify.breakpoint.xs ? 'mt-4' : ''">
            <v-btn
              v-for="(item, index) in editForm"
              :key="index"
              class="primary ma-2"
              elevation="2"
              x-small
              @click="removeBridge(index)"
            >
              {{ `S${index}` }}
              <v-icon right>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="!isloading">
          <v-form
            ref="form"
            lazy-validation
            class="pt-0"
            enctype="multipart/form-data"
          >
            <div v-for="(bridge, indexForm) in editForm" :key="indexForm">
              <v-divider class="my-4"></v-divider>
              <div>
                <div class="primary--text">
                  <h3>ค่าเริ่มต้นของช่วงสะพาน ({{ `S${indexForm}` }})</h3>
                </div>
                <v-row class="mt-2">
                  <v-col
                    cols="12"
                    sm="6"
                    v-for="(item, index) in editSegmentField"
                    :key="index"
                  >
                    <v-row align="center">
                      <v-col cols="5">
                        <div class="form-text black--text">
                          {{ item.label }}
                          <span class="red--text"> * </span>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field
                          v-if="item.number"
                          v-model="editForm[indexForm][item.model]"
                          class="right-input"
                          placeholder="0"
                          dense
                          outlined
                          type="number"
                          min="0"
                          pattern="\d*"
                          :rules="[rules.required]"
                          :disabled="isUpdateLoading"
                          hide-details="auto"
                        ></v-text-field>
                        <v-select
                          v-else-if="item.select"
                          v-model="editForm[indexForm][item.model]"
                          placeholder="เลือก"
                          dense
                          outlined
                          :items="selectionForm[item.select.list]"
                          item-text="name"
                          item-value="id"
                          :rules="[rules.required]"
                          :disabled="isUpdateLoading"
                          hide-details="auto"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-row align="center">
                      <v-col cols="5">
                        <div class="form-text black--text">
                          จำนวนคานตามยาว (GB)
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field
                          v-model="editForm[indexForm].amount_beam_major"
                          class="right-input"
                          placeholder="0"
                          dense
                          outlined
                          type="number"
                          step="1"
                          min="0"
                          pattern="\d*"
                          hide-details="auto"
                          :disabled="isUpdateLoading"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row align="center">
                      <v-col cols="5">
                        <div class="form-text black--text">
                          จำนวนค้ำยันคานตามยาว (DP)
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field
                          v-model="editForm[indexForm].amount_beam_minor"
                          class="right-input"
                          placeholder="0"
                          dense
                          outlined
                          type="number"
                          step="1"
                          min="0"
                          pattern="\d*"
                          hide-details="auto"
                          :disabled="isUpdateLoading"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div v-if="!editForm[indexForm].is_bridge_end" class="mt-4">
                <div class="primary--text">
                  <h3>ค่าเริ่มต้นของตอม่อ ({{ `P${indexForm}` }})</h3>
                </div>
                <v-row class="mt-2">
                  <v-col
                    cols="12"
                    sm="6"
                    v-for="(item, index) in editPierField"
                    :key="index"
                  >
                    <v-row align="center">
                      <v-col cols="5">
                        <div class="form-text black--text">
                          {{ item.label }}
                          <span class="red--text"> * </span>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <v-text-field
                          v-if="item.number"
                          v-model="editForm[indexForm][item.model]"
                          class="right-input"
                          placeholder="0"
                          dense
                          outlined
                          type="number"
                          min="0"
                          pattern="\d*"
                          :rules="[rules.required]"
                          hide-details="auto"
                          :disabled="isUpdateLoading"
                        ></v-text-field>
                        <v-select
                          v-else-if="item.select"
                          v-model="editForm[indexForm][item.model]"
                          class="right-input"
                          placeholder="เลือก"
                          dense
                          outlined
                          :items="selectionForm[item.select.list]"
                          :rules="[rules.required]"
                          item-text="name"
                          item-value="id"
                          hide-details="auto"
                          :disabled="isUpdateLoading"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <v-row v-if="bridgeOverview.permissions.can_delete" class="mt-2">
                <v-col class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    outlined
                    :disabled="isUpdateLoading"
                    @click="
                      confirmRemoveBridge(
                        editForm[indexForm].bridge_part_id,
                        indexForm
                      )
                    "
                  >
                    <v-icon left>mdi-trash-can-outline</v-icon>
                    ลบข้อมูลทั้งหมด
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </div>
        <div v-else>
          <v-divider class="my-4"></v-divider>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-progress-circular
              color="grey lighten-2"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </div>
      </div>
    </TemplateDialog>
    <ConfirmDialog
      v-model="isConfirmDialog"
      :dialog-title="dialogTitle"
      :isLoading="isUpdateLoading"
      @confirm="updateBridgeInfo()"
      @cancel="closeConfirm()"
    />
    <ConfirmDialog
      v-model="isConfirmDeleteDialog"
      :dialog-title="'ยืนยันลบข้อมูลทั้งหมด'"
      :dialog-icon="'mdi-close-circle-outline'"
      :dialog-icon-color="'primary'"
      :isLoading="isUpdateLoading"
      @confirm="removeBridgeSegmentAndPier()"
      @cancel="closeConfirm()"
    />
    <SelectBridgeComponentDialog
      v-model="selectBridgeComponentDialog"
      :bridge-segment-selected="bridgeSegmentSelected"
      :bridge-selected="bridgeSelected"
      @confirm="updateSelectBridgeComponent"
      @close="selectBridgeComponentDialog = false"
    />
  </div>
</template>

<script>
import TemplateDialog from './TemplateDialog.vue'
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
import SelectBridgeComponentDialog from './SelectBridgeComponentDialog.vue'
import { mapState } from 'vuex'

export default {
  components: {
    TemplateDialog,
    ConfirmDialog,
    SelectBridgeComponentDialog
  },
  props: {
    value: Boolean,
    bridgeSelected: Object
  },
  watch: {
    async value (newVal, oldVal) {
      this.isOpenDialog = newVal
      if (newVal) {
        if (!this.selectionForm.segment_type) {
          await this.getRef('segment_type')
        }
        if (!this.selectionForm.segment_material) {
          await this.getRef('segment_material')
        }
        if (!this.selectionForm.pier_protection) {
          await this.getRef('pier_protection')
        }
        if (!this.selectionForm.pier_br) await this.getRef('pier_br')
        if (!this.selectionForm.pier_type) await this.getRef('pier_type')
        if (!this.selectionForm.pier_material) {
          await this.getRef('pier_material')
        }
      }
    }
  },
  data: () => ({
    isOpenDialog: false,
    isConfirmDialog: false,
    dialogTitle: 'ยืนยันการแก้ไขข้อมูล ?',
    dialogIcon: 'mdi-close',
    dialogIconColor: 'primary',
    editSegmentField: [
      {
        model: 'segment_length',
        label: 'ความยาวช่วงสะพาน (ม.)',
        number: true
      },
      {
        model: 'segment_material_id',
        label: 'วัสดุโครงสร้างสะพาน',
        select: {
          list: 'segment_material'
        }
      },
      {
        model: 'segment_type_id',
        label: 'ประเภทโครงสร้างสะพาน',
        select: {
          list: 'segment_type'
        }
      }
    ],
    editPierField: [
      {
        model: 'pier_protection',
        label: 'การป้องกันตอม่อ',
        select: {
          list: 'pier_protection'
        }
      },
      {
        model: 'pier_br',
        label: 'ชนิดแผ่นรองรับคาน',
        select: {
          list: 'pier_br'
        }
      },
      {
        model: 'amount_cb',
        label: 'จำนวนคานรัดหัวเสา (CB)',
        number: true
      },
      {
        model: 'amount_br',
        label: 'จำนวนแผ่นรองรับคาน',
        number: true
      },
      {
        model: 'pier_type',
        label: 'ประเภทตอม่อ',
        select: {
          list: 'pier_type'
        }
      },
      {
        model: 'pier_material',
        label: 'วัสดุตอม่อ',
        select: {
          list: 'pier_material'
        }
      },
      {
        model: 'amount_pr',
        label: 'จำนวนตอม่อ (PR)',
        number: true
      },
      {
        model: 'amount_bc',
        label: 'จำนวนค้ำยันตอม่อ (BC)',
        number: true
      }
    ],
    editForm: {},
    selectBridgeComponentDialog: false,
    isConfirmDeleteDialog: false,
    bridgeSegmentSelected: [],
    isloading: false,
    isUpdateLoading: false,
    rules: {
      required: (value) => !!value || 'กรุณากรอกข้อมูล'
    },
    bridgePartId: null,
    seq: null
  }),
  computed: {
    ...mapState('bridgeDetail', ['bridgeOverview']),
    selectionForm: {
      get () {
        return this.$store.state.ref
      },
      set (ref) {
        this.$store.commit('ref/SET_SELECTION', ref)
      }
    }
  },
  methods: {
    close () {
      //   this.$refs.form.reset()
      this.updateSelectBridgeComponent([])
      this.$emit('close', false)
    },
    closeConfirm () {
      this.isConfirmDialog = false
      this.isConfirmDeleteDialog = false
    },
    confirmDialog () {
      if (this.$refs.form.validate()) {
        this.isConfirmDialog = true
      } else {
        const invalidField = this.$refs.form.inputs.find((e) => !e.valid)
        if (invalidField) {
          invalidField.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }
    },
    async updateBridgeInfo () {
      this.isUpdateLoading = true
      for (const [key, value] of Object.entries(this.editForm)) {
        console.log(value)
        const bridgepartid = value.bridge_part_id
        const segmentForm = {
          amount_segment: 1,
          segment_length: value.segment_length,
          segment_type_id: value.segment_type_id,
          segment_material_id: value.segment_material_id,
          amount_beam_major: value.amount_beam_major,
          amount_beam_minor: value.amount_beam_minor,
          amount_slab: value.amount_slab
        }
        console.log('set bridge segment')
        console.log(bridgepartid, key, segmentForm)
        const isSegmentUpdate = await this.$store.dispatch('setBridgeSegment', {
          id: bridgepartid,
          seq: key,
          payload: segmentForm
        })
        console.log(isSegmentUpdate)
        const pierForm = {
          amount_segment: 1, //
          is_bridge_end: 1,
          pier_type_id: value.pier_type,
          pier_br_id: value.pier_br,
          amont_pr: value.amount_pr,
          amount_bc: value.amount_bc,
          amount_cb: value.amount_cb,
          amount_br: value.amount_br,
          pier_protection_id: value.pier_protection,
          pier_material_id: value.pier_material
        }
        console.log('set bridge pier')
        console.log(bridgepartid, key, pierForm)
        if (!value.is_bridge_end) {
          const isPierUpdate = await this.$store.dispatch('setBridgePier', {
            id: bridgepartid,
            seq: key,
            payload: pierForm
          })
          console.log(isPierUpdate)
        }
      }
      console.log('finish set bridge')
      this.updateSelectBridgeComponent([])
      this.$emit('close', false)
      this.isloading = false
      this.isUpdateLoading = false

      this.isConfirmDialog = false
    },
    async removeBridgeSegmentAndPier () {
      this.isUpdateLoading = true
      const payloaddelete = {
        bridgePartId: this.bridgePartId,
        seq: this.seq
      }
      const editFormTemp = { ...this.editForm }
      this.bridgeSegmentSelected = this.bridgeSegmentSelected.filter(
        (segment) => segment.seq !== this.seq
      )
      this.$delete(this.editForm, this.seq)
      const isdelete = await this.$store.dispatch(
        'delBridgeSegmentAndPier',
        payloaddelete
      )
      if (isdelete) {
        const payload = { bridgeCode: this.$route.query.id }
        await this.$store.dispatch('getBridgeOverview', payload)
        this.$emit('update')

        this.editForm = {}
        for (const [key, value] of Object.entries(editFormTemp)) {
          if (parseInt(key) < parseInt(this.seq)) {
            this.editForm[key] = value
          }
          if (parseInt(key) > parseInt(this.seq)) {
            this.editForm[key - 1] = value
          }
        }
        this.bridgeSegmentSelected.map((bridgepart) => {
          if (parseInt(bridgepart.seq) > parseInt(this.seq)) {
            bridgepart.seq = (parseInt(bridgepart.seq) - 1).toString()
          }
          return bridgepart
        })
      }
      this.isUpdateLoading = false
      this.isConfirmDeleteDialog = false
    },
    async updateSelectBridgeComponent (bridgeSegmentSelected) {
      this.selectBridgeComponentDialog = false
      this.isloading = true
      const editFormTemp = { ...this.editForm }
      this.editForm = {}
      for (const bridgeSegment of bridgeSegmentSelected) {
        if (!editFormTemp[bridgeSegment.seq] && bridgeSegment.seq !== null) {
          const resultBridgeSegment = await this.getBridgeSegment(
            bridgeSegment.seq
          )
          const resultBridgePier = await this.getBridgePier(bridgeSegment.seq)
          this.editForm[bridgeSegment.seq] = {
            ...resultBridgeSegment,
            ...resultBridgePier
          }
        } else if (editFormTemp[bridgeSegment.seq]) {
          this.editForm[bridgeSegment.seq] = editFormTemp[bridgeSegment.seq]
        }
      }
      this.bridgeSegmentSelected = bridgeSegmentSelected
      this.isloading = false
    },
    removeBridge (index) {
      this.bridgeSegmentSelected = this.bridgeSegmentSelected.filter(
        (segment) => segment.seq !== index
      )
      this.$delete(this.editForm, index)
    },
    async getRef (ref) {
      const selection = await this.$store.dispatch('ref/getRef', {
        method: ref
      })
      this.selectionForm = { ref, selection: selection }
    },
    async getBridgeSegment (seq) {
      const result = await this.$store.dispatch('getBridgeSegment', {
        bridgeCode: this.$route.query.id,
        partCode: this.bridgeSelected.partcode,
        seq
      })
      const editSegmentForm = {
        bridge_part_id: result.bridge_part_id,
        segment_length: result.bridge_segment.length,
        segment_type_id: result.bridge_segment.type_id,
        segment_material_id: result.segment_material.id,
        amount_beam_major: result.bridge_segment.amount_beam_major,
        amount_beam_minor: result.bridge_segment.amount_beam_minor,
        amount_slab: result.bridge_segment.amount_slab
      }
      return editSegmentForm
    },
    async getBridgePier (seq) {
      const result = await this.$store.dispatch('getBridgePier', {
        bridgeCode: this.$route.query.id,
        partCode: this.bridgeSelected.partcode,
        seq
      })
      console.log(result)
      if (result) {
        const editPierForm = {
          pier_protection: result.pier_protection.id,
          pier_br: result.pier_br.id,
          amount_cb: result.bridge_pier.amount_cb,
          amount_br: result.bridge_pier.amount_br,
          pier_type: result.bridge_pier.type_id,
          pier_material: result.pier_material.id,
          amount_pr: result.bridge_pier.amont_pr,
          amount_bc: result.bridge_pier.amount_bc,
          is_bridge_end: false
        }
        return editPierForm
      } else {
        return {
          is_bridge_end: true
        }
      }
    },
    confirmRemoveBridge (bridgePartId, seq) {
      this.bridgePartId = bridgePartId
      this.seq = seq
      this.isConfirmDeleteDialog = true
    }
  }
}
</script>

<style scoped>
/* input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
} */
.right-input >>> input {
  text-align: right;
}
</style>
