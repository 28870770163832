<template>
  <div>
    <v-dialog
      v-model="isOpenDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
      :transition="
        $vuetify.breakpoint.xs
          ? 'dialog-bottom-transition'
          : 'dialog-transition'
      "
      :max-width="width"
      persistent
    >
      <v-card>
        <div class="primary pb-2 white--text">
          <v-card-title>
            {{ title }}
            <v-btn icon class="ml-auto white--text" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="pb-0 primary">
            {{ bridgeName }}
          </v-card-subtitle>
        </div>

        <v-card-text class="ma-0 pa-0">
          <slot></slot>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn elevation="0" style="width: 140px" @click="close">
            ยกเลิก
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="clearable" elevation="0" style="width: 140px" @click="clear">
            <v-icon>mdi-close</v-icon> ล้างข้อมูล
          </v-btn>
          <v-btn
            class="green white--text"
            elevation="0"
            style="width: 140px"
            :disabled="isDisabledSubmit || isUpdateLoading"
            @click="confirm()"
          >
            บันทึก
            <v-progress-circular
              v-if="isUpdateLoading"
              color="white"
              :size="20"
              indeterminate
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: String,
    clearable: Boolean,
    width: {
      type: Number,
      default: () => {
        return 760
      }
    },
    isDisabledSubmit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isUpdateLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    isOpenDialog: false
  }),
  watch: {
    async value (newVal, oldVal) {
      this.isOpenDialog = newVal
    }
  },
  computed: {
    bridgeName () {
      return this.$store.state.appbarText.header
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },

    confirm () {
      this.$emit('confirm')
    },

    clear () {
      this.$emit('clear')
    }
  }
}
</script>

<style></style>
