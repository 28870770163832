<template>
  <div>
    <div class="mt-4" style="flex-grow: 0.8">
      <v-tabs
        ref="tabs"
        class="pa-0 ma-0"
        v-model="tab"
        fixed-tabs
        centered
        :icons-and-text="$vuetify.breakpoint.smAndUp"
      >
        <v-tab
          v-for="(item, index) in dataTab"
          :key="index"
          v-show="isDialog ? item.showDialog : true"
          style="min-width: 20px"
          @click="sliderResize"
        >
          {{ $vuetify.breakpoint.smAndUp ? item.name : '' }}
          <v-icon>{{ item.icon }}</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" style="height: 100%">
        <v-tab-item>
          <InventoryDetail :isDialog="isDialog" :bridgeDetail="bridgeDetail" />
        </v-tab-item>
        <v-tab-item> <InventoryHistory :isDialog="isDialog" /> </v-tab-item>
        <v-tab-item>
          <InventoryBridgeStructure :isDialog="isDialog" />
        </v-tab-item>
        <v-tab-item v-if="!isDialog" style="height: 100%">
          <InventoryBridgeComponent />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import InventoryDetail from '@/components/Inventory/Detail/InventoryDetail.vue'
import InventoryHistory from '@/components/Inventory/Detail/InventoryHistory.vue'
import InventoryBridgeStructure from '@/components/Inventory/Detail/InventoryBridgeStructure.vue'
import InventoryBridgeComponent from '@/components/Inventory/Detail/InventoryBridgeComponent.vue'
// import BridgeIcon from '@/components/icon/BridgeIcon.vue'
export default {
  props: {
    isDialog: {
      type: Boolean,
      default: false
    }
  },
  components: {
    InventoryDetail,
    InventoryHistory,
    InventoryBridgeStructure,
    InventoryBridgeComponent
    // BridgeIcon
  },
  data: () => ({
    tab: null,
    showMap: false,
    dataTab: [
      {
        name: 'ข้อมูลทั่วไป',
        shortName: 'ข้อมูล',
        showDialog: true,
        icon: 'mdi-information-outline'
      },
      {
        name: 'ประวัติการใช้งาน',
        shortName: 'ประวัติ',
        showDialog: true,
        icon: 'mdi-history'
      },
      {
        name: 'โครงสร้างสะพาน',
        shortName: 'โครงสร้าง',
        showDialog: true,
        icon: 'mdi-bridge'
      },
      {
        name: 'ชิ้นส่วนสะพาน',
        shortName: 'ชิ้นส่วน',
        showDialog: false,
        icon: '$BridgeComponentIcon'
      }
    ]
  }),
  computed: {
    bridgeDetail () {
      return this.$store.state.bridgeDetail.detail
    }
  },
  methods: {
    sliderResize () {
      this.$refs.tabs.onResize()
    }
  }
}
</script>

<style></style>
